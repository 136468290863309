import { gql } from "@apollo/client"

export const GET_ARTICLES = gql`
  query GetArticles($offset: Int, $limit: Int, $newsCategory: [QueryArgument]) {
    entryCount(typeId: "11", newsCategory: $newsCategory)
    articles: entries(
      typeId: "11"
      offset: $offset
      limit: $limit
      newsCategory: $newsCategory
      orderBy: "publishedDate DESC"
    ) {
      title
      ... on newsArticle_default_Entry {
        body
        uri
        publishedDate
        newsCategory {
          ... on newsCategories_default_Entry {
            id
            title
          }
        }
        image {
          id
          ... on images_Asset {
            id
            url
            title
          }
        }
      }
    }
  }
`
