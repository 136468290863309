import React from "react"

const NewsSkeletonCard = () => {
  return (
    <div className="rounded-xl overflow-hidden bg-white">
      <div className="h-0 pb-4:3 w-full bg-charcoal-400 bg-opacity-30 animate-pulse"></div>
      <div className="px-4 py-5 space-y-2.5">
        <div className="bg-charcoal-400 animate-pulse bg-opacity-20 h-4 w-16 rounded-md"></div>
        <div className="bg-charcoal-400 animate-pulse bg-opacity-20 h-6 w-48 rounded-md"></div>
        <div className="bg-charcoal-400 animate-pulse bg-opacity-20 h-12 w-full rounded-md"></div>
        <div className="bg-charcoal-400 animate-pulse bg-opacity-20 h-7 w-28 rounded-full"></div>
      </div>
    </div>
  )
}

export default NewsSkeletonCard
