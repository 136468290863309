import React from "react"

import NewsSkeletonCard from "./NewsSkeletonCard"

const NewsSkeletonList = ({ incrementValue }) => {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-gs my-16">
      {[...Array(incrementValue)].map((_, index) => (
        <NewsSkeletonCard key={`skeleton-${index}`} />
      ))}
    </div>
  )
}

export default NewsSkeletonList
