import React, { useState } from "react"
import { graphql } from "gatsby"
import { useQuery } from "@apollo/client"

import { BLUE, EMPTY_CATEGORY_DEFAULT } from "../constants"
import { GET_ARTICLES } from "../graphql/run-time-queries"
import { convertGatsbySourceCraftIdtoCraftId } from "../utils"

import Layout from "../components/Layout/Layout"
import FlexibleContent from "../components/FlexibleContent"
import NewsSkeletonList from "../components/News/NewsSkeletonList"
import ArticlesFilter from "../components/Articles/ArticlesFilter"
import ArticlesList from "../components/Articles/ArticlesList"

const ArticlesPage = props => {
  const { title, metaDescription, metaTitle, flexibleContent } =
    props.data.craftNewsIndexNewsIndexEntry
  const { categories } = props.data.allCraftNewsCategoriesDefaultEntry

  const [offset, setOffset] = useState(0)
  const [newsCategory, setNewsCategory] = useState(null)
  const incrementValue = 6

  const { loading, error, data, fetchMore } = useQuery(GET_ARTICLES, {
    variables: { offset: 0, limit: incrementValue, newsCategory: newsCategory },
  })

  const handleChange = event => {
    const val =
      event.target.value === EMPTY_CATEGORY_DEFAULT
        ? null
        : convertGatsbySourceCraftIdtoCraftId(event.target.value)

    setNewsCategory(val)

    const newOffset = 0
    setOffset(newOffset)

    fetchMore({
      variables: {
        offset: newOffset,
        newsCategory: val,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEntries = fetchMoreResult.articles
        return {
          articles: [...newEntries],
          entryCount: fetchMoreResult.entryCount,
        }
      },
    })
  }

  const handleClick = () => {
    const newOffset = offset + incrementValue
    setOffset(newOffset)
    fetchMore({
      variables: {
        offset: newOffset,
        limit: incrementValue,
        newsCategory: newsCategory,
      },
      // concatenate old and new entries
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEntries = fetchMoreResult.articles
        return {
          articles: [...previousResult.articles, ...newEntries],
          entryCount: fetchMoreResult.entryCount,
        }
      },
    })
  }

  const renderArticles = () => {
    if (loading) return <NewsSkeletonList incrementValue={incrementValue} />
    if (error) return <p className="my-16">Oops, there was an error</p>

    if (data.articles.length === 0)
      return (
        <p className="my-16">
          Sorry, there were no articles that match your selection
        </p>
      )
    return <ArticlesList articles={data.articles} />
  }

  return (
    <Layout
      title={metaTitle || title}
      description={metaDescription}
      theme={BLUE}
      location={props.location}
    >
      <div className="js-transition-link-target" data-theme={BLUE}>
        <div className="px-ogs py-20 lg:py-30 md:grid md:grid-cols-12 md:gap-x-gs">
          <div className="md:col-span-12 xl:col-span-10 xl:col-start-2">
            <div className="space-y-8 md:space-y-0 md:flex md:justify-between md:items-center mb-4 lg:mb-6 md:space-x-gs">
              <h1 className="font-semibold text-4xl lg:text-5xl text-blue-500">
                {title}
              </h1>
              <ArticlesFilter
                categories={categories}
                handleChange={handleChange}
                emptyLabel="All Categories"
              />
            </div>
            {renderArticles()}
          </div>
          {offset + incrementValue < data?.entryCount && (
            <div className="md:col-span-12 xl:col-span-10 xl:col-start-2">
              <div className="inline-flex justify-center w-full">
                <button
                  onClick={() => {
                    handleClick()
                  }}
                  className="Button Button--primary Button--larger"
                >
                  Load More Articles
                </button>
              </div>
            </div>
          )}
        </div>
        <FlexibleContent flexibleContent={flexibleContent} />
      </div>
    </Layout>
  )
}

export default ArticlesPage

export const pageQuery = graphql`
  query {
    allCraftNewsCategoriesDefaultEntry {
      categories: nodes {
        id
        title
      }
    }
    craftNewsIndexNewsIndexEntry {
      metaTitle
      metaDescription
      title
      flexibleContent {
        ...flexibleContentFragment
      }
    }
  }
`
